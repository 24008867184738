import { render, staticRenderFns } from "./HelpCenter.vue?vue&type=template&id=e89e7af2&scoped=true&"
import script from "./HelpCenter.vue?vue&type=script&lang=js&"
export * from "./HelpCenter.vue?vue&type=script&lang=js&"
import style0 from "./HelpCenter.vue?vue&type=style&index=0&id=e89e7af2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e89e7af2",
  null
  
)

export default component.exports